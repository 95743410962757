import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenKey = "token";

  constructor() {}

  set(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  get(): string {
    return localStorage.getItem(this.tokenKey);
  }

  has(): boolean {
    return !!this.get();
  }

  remove(): void {
    localStorage.removeItem(this.tokenKey);
  }
}
