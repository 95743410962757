import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {TokenService} from "../services/token.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/models/app-state";
import {SnackbarOpenAction} from "../../shared/store/actions/snackbar.actions";

@Injectable({
    providedIn: 'root'
})
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(
        private token: TokenService,
        private router: Router,
        private store: Store<AppState>
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.token.remove();
                    this.router.navigate(['/auth/login']);
                    this.store.dispatch(new SnackbarOpenAction({message: 'Invalid token', action: 'Ok'}))
                }
                return throwError(error);
            })
        );
    }
}