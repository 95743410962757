import { Action } from '@ngrx/store';
import { MatSnackBarConfig } from '@angular/material';

export enum SnackbarActionTypes {
    SNACKBAR_OPEN = '[SNACKBAR] Open Snackbar',
    SNACKBAR_CLOSE = '[SNACKBAR] Close Snackbar',
}

export class SnackbarOpenAction implements Action {
    readonly type = SnackbarActionTypes.SNACKBAR_OPEN;
  
    constructor(
        public payload: {
            message: string,
            action?: string,
            config?: MatSnackBarConfig
        }
    ) { }
  
  }
  
  export class SnackbarCloseAction implements Action {
    readonly type = SnackbarActionTypes.SNACKBAR_CLOSE;
  }
  
  export type SnackbarAction = 
    | SnackbarOpenAction 
    | SnackbarCloseAction;