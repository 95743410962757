import {AuthActionTypes} from "../../auth/store/actions/auth.actions";

export function clearState(reducer) {
    return function (state, action) {
        if (action.type === AuthActionTypes.LOGOUT) {
            state = undefined;
        }

        return reducer(state, action);
    };
}