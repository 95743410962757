import { Action } from '@ngrx/store';

export enum AuthActionTypes {
    LOGIN = '[AUTH] Login',
    LOGIN_SUCCESS = '[AUTH] Login Success',
    LOGIN_FAILURE = '[AUTH] Login Failure',

    RECOVER_PASSWORD = '[AUTH] Recover Password',
    RECOVER_PASSWORD_SUCCESS = '[AUTH] Recover Password Success',
    RECOVER_PASSWORD_FAILURE = '[AUTH] Recover Password Failure',

    LOGOUT = '[AUTH] Logout',
};

export class LoginAction implements Action {
    readonly type = AuthActionTypes.LOGIN;

    constructor(public payload: {email, password}) {}
}

export class LoginSuccessAction implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCESS;

    constructor(public payload: string) {}
}

export class LoginFailureAction implements Action {
    readonly type = AuthActionTypes.LOGIN_FAILURE;

    constructor(public payload: Error) {}
}

export class RecoverPasswordAction implements Action {
    readonly type = AuthActionTypes.RECOVER_PASSWORD;

    constructor(public payload: string) {}
}

export class RecoverPasswordSuccessAction implements Action {
    readonly type = AuthActionTypes.RECOVER_PASSWORD_SUCCESS;

    constructor(public payload: string) {}
}

export class RecoverPasswordFailureAction implements Action {
    readonly type = AuthActionTypes.RECOVER_PASSWORD_FAILURE;

    constructor(public payload: Error) {}
}

export class LogoutAction implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

export type AuthAction = 
    | LoginAction
    | LoginSuccessAction
    | LoginFailureAction
    | RecoverPasswordAction
    | RecoverPasswordSuccessAction
    | RecoverPasswordFailureAction
    | LogoutAction;