import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './layout/containers/app-layout/app-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth.guard';


const routes: Routes = [
  { path: 'auth', canActivate: [NoAuthGuard], loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  { path: '', canActivate: [AuthGuard], loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
